import React from "react";
import Layout from "../components/Layout";

import RealEstate from "../components/RealEstate";

const RealEstatePage = ({ data }) => (
  <Layout>
    <RealEstate data={data} />
  </Layout>
);

export default RealEstatePage;
