import React from "react";
import styled from "styled-components";
import { navigate } from "gatsby";
import { Row, Col } from "react-styled-flexboxgrid";

import { Primary, Alternate } from "../../components/Section";
import { Button } from "../../components/Button";
import { rem } from "polished";
import LogoRealEstate from "../../images/logo-turtle.jpeg";
const StyledRealEstateLogo = styled.img`
  height: ${rem(400)};
`;

const RealEstate = () => {
  return (
    <section>
      <Primary centered>
        <Row>
          <Col xs={12}>
            <StyledRealEstateLogo
              src={LogoRealEstate}
              alt="mjmd RealEstate logo"
            />
          </Col>
        </Row>
      </Primary>
      <Alternate>
        <Row>
          <Col xs={12} md={6}>
            <h2>Turtle Property Management</h2>
            <p>
              We aquire, manage, and sell multifamily communities. We help make
              your family goals a reality!
            </p>
            <Button onClick={() => navigate("/reach-us")}>Reach Us</Button>
          </Col>
          <Col xs={12} md={6}>
            <form></form>
          </Col>
        </Row>
      </Alternate>
    </section>
  );
};

RealEstate.propTypes = {};

export default RealEstate;
